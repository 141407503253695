import React,{useEffect, useState} from 'react'
import { Typography ,Box, Paper, Card, IconButton} from '@material-ui/core';
import Viewer from 'react-viewer';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CancelIcon from '@material-ui/icons/Cancel';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import {useHistory} from "react-router-dom";
import Galeria from './Galeria';
const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.main,
    },
  }));
const estiloDefault={ position: "absolute", width:  "15rem", paddingLeft:".2rem" }

const CajaLeyenda = ({leyendaMap,estilo,setLeyendaMap,tipo }) => {
    const history = useHistory();
    const source = axios.CancelToken.source();
    const classes = useStyles();
    const idusu = localStorage.getItem("UsuId");
    const [listGaleria, setListGaleria] = useState([]);
    const [visible, setVisible] = useState(false);
    const [cargando, setCargando] = useState(false);
    useEffect(()=>{
        consultarImagenes(leyendaMap.id)
        return () => {
            source.cancel();
        }
    },[leyendaMap])

    const consultarImagenes = (selectedID) => {
        const data = qs.stringify({
            Llave: selectedID,
            Usuario: idusu,
            idDispositivo: "987gyubjhkkjh",
        });
        const url = tipo &&  tipo===1 ? "comercio/v1/file/list" :"recorrido-file-list";

        function respuesta(response) {
            if (response[0].Id === -1) {
                setListGaleria([]);
               
            } else {
                setListGaleria(response);
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const viewer = <Paper square>
    <Viewer
        visible={visible}
        onClose={() => {
            setVisible(false);
        }}
        images={listGaleria.map((element) => {
            return ({src: element.Dir + element.Nom, alt: element.Descr})
        })}
       // activeIndex={index}
        zIndex={2000}
    />
    </Paper>

    const listarGaleria =
    listGaleria.length > 0 ? (
        <Galeria
            galeria={listGaleria}
        />
    ) : null
    return (
    <Box style={estilo??estiloDefault}>
        
        <Backdrop className={classes.backdrop} open={cargando} onClick={()=>setCargando(false)}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper elevation={3}>
            <Card style={{display:"flex",}}>
            <Box>
                <IconButton size='small' onClick={()=>setLeyendaMap([])}>
                    <CancelIcon fontSize="small"/>
                </IconButton>  
                { /* leyendaMap.foto===1 ?  
                <Box>
                    <IconButton size='small' onClick={()=>{setCargando(true)}} >
                        <PhotoLibraryIcon fontSize="small"/>
                    </IconButton>  
                </Box>
                :null */
            }
            </Box>
            
            <Box p={1}>                    
                <Typography variant='body2'>{leyendaMap.texto}</Typography>    
                { leyendaMap?.texto2 !== "" ?
                    <Typography variant='body2' style={{marginTop:".3rem",marginBottom:".3rem"}}>
                        {leyendaMap.texto2}
                    </Typography>                
                :null  
                }  
                { leyendaMap?.texto3!==""?
                    <Typography variant='body2'  style={{marginTop:".3rem",marginBottom:".3rem"}}>{leyendaMap.texto3}</Typography>                  
                :null  
                }  
                { leyendaMap?.texto4!==""?
                    <Typography variant='body2' style={{marginTop:".3rem",marginBottom:".3rem"}}>{leyendaMap.texto4}</Typography>                  
                    :null  
                }  
                { leyendaMap?.texto5!==""?
                    <Typography variant='body2'>{leyendaMap.texto5}</Typography>                  
                    :null  
                }   
            </Box>           
            </Card>
        </Paper>
        {listGaleria.length!==0 ? listarGaleria:null }
    </Box>   
  )
}

export default CajaLeyenda